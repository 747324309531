import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UsersSelectorProvider } from '@hkm/components/shared/features/UsersSelector/store/Provider';
import { UsersSelector } from '@hkm/components/shared/features/UsersSelector/UsersSelector';
import { MultiselectFiltersConfigItem } from '@hkm/components/TaskManagement/Dashboard/config/multiselectFiltersConfig';
import { getDashboardQuickFilter } from '@hkm/components/TaskManagement/Dashboard/domain/selectors';
import { MappedConfigurationUser } from '@hkm/components/TaskManagement/Dashboard/mappers';
import { setTasksSelectedFilterUser } from '@hkm/components/TaskManagement/relations/domain/actions';
import { useAcSelectTranslations } from '@hkm/features/translations/useAcSelectTranslations';

import { AcMultiSelect } from '@ac/mobile-components/dist/components/multiselect';

interface ComponentProps {
  filterItem: MultiselectFiltersConfigItem;
}

const MultiselectFilterItem: FC<ComponentProps> = (props) => {
  const { filterItem } = props;
  const { isSearchField, updateValue } = filterItem;
  const { t } = useTranslation();
  const { translations } = useAcSelectTranslations();
  const dispatch = useDispatch();
  const currentQuickFilter = useSelector(getDashboardQuickFilter);

  const disabled = useMemo(
    () =>
      filterItem.relatedQuickFilters?.length
        ? filterItem.relatedQuickFilters.some(
            (field) => field === currentQuickFilter
          )
        : false,
    [currentQuickFilter, filterItem.relatedQuickFilters]
  );

  const selectSearchFieldValue = useCallback(
    (selectedUsers?: MappedConfigurationUser[]) => {
      if (!selectedUsers?.length) {
        updateValue([]);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(setTasksSelectedFilterUser.success(undefined));

        return;
      }
      updateValue([selectedUsers[0].id]);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(setTasksSelectedFilterUser.success(selectedUsers));
    },
    [dispatch, updateValue]
  );

  return isSearchField ? (
    <UsersSelectorProvider>
      <UsersSelector
        label={filterItem.label}
        disabled={disabled}
        selectedUserIds={filterItem.value || []}
        onSelectUsers={selectSearchFieldValue}
      />
    </UsersSelectorProvider>
  ) : (
    <AcMultiSelect
      className="ac-spacing-top-md"
      label={filterItem.label}
      placeholder={t('GLOBAL.SELECT')}
      disabled={disabled}
      selectedItem={filterItem.value || []}
      itemsList={filterItem.options}
      showInputs={true}
      testSelector={`task-management-filter-${filterItem.name}`}
      onChange={updateValue}
      translations={translations}
    />
  );
};

export default memo(MultiselectFilterItem);
