/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from '@hkm/features/app/commands/language/changeLanguage';
import { useLanguagesDictionary } from '@hkm/features/configuration/dictionaries/languages/useLanguagesDictionary';
import { useAcSelectTranslations } from '@hkm/features/translations/useAcSelectTranslations';
import i18n from 'i18next';

import {
  AcSelect,
  AcSelectPattern,
} from '@ac/mobile-components/dist/components/select';

export const Languages = (): JSX.Element => {
  const { t } = useTranslation();
  const { translations } = useAcSelectTranslations();
  const languageDictionary = useLanguagesDictionary();

  const languages = useMemo(
    () => languageDictionary.active.format.toAcSelect(),
    [languageDictionary.active.format]
  );

  return (
    <AcSelect
      selectedItem={i18n.language}
      itemsList={languages}
      label={t('MENU.LANGUAGE')}
      showSearch={true}
      showInputs={true}
      clearable={false}
      readonly={languages.length <= 1}
      pattern={AcSelectPattern.Borderless}
      testSelector="language"
      onChange={changeLanguage}
      translations={translations}
    />
  );
};
