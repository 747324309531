import React, { FC, memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Navigate, useParams } from 'react-router';
import * as appActions from '@hkm/components/App/domain/actions';
import { selectNotes } from '@hkm/components/App/domain/selectors';
import AttendantFabContainer from '@hkm/components/Attendant/RoomDetails/body/AttendantFabContainer';
import AttendantRoomDetailsBody from '@hkm/components/Attendant/RoomDetails/body/AttendantRoomDetailsBody';
import { AlertBar } from '@hkm/components/Attendant/shared/components/alertBar/AlertBar';
import * as actions from '@hkm/components/Attendant/shared/domain/actions';
import {
  selectAssignedSheetState,
  selectAttendantAssignmentDataState,
  selectIsLoadingOrEditingSheets,
  selectPendingRoomQueue,
  selectServiceRequested,
} from '@hkm/components/Attendant/shared/domain/selectors';
import { useRedirectToAssignment } from '@hkm/components/Attendant/shared/hooks/useRedirectToAssignment';
import { findAttendantRoom } from '@hkm/components/Attendant/shared/utils/findAttendantRoom';
import { getNextRoomId } from '@hkm/components/Attendant/shared/utils/getNextRoomId';
import { isTaskProgress } from '@hkm/components/Attendant/shared/utils/isTaskProgress';
import { selectEffectiveValues } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import DoNotMoveHeaderIcon from '@hkm/components/shared/DoNotMove/DoNotMoveHeaderIcon';
import ConnectedHeader from '@hkm/components/shared/LayoutComponents/ConnectedHeader';
import HeaderTwoLineTitle from '@hkm/components/shared/LayoutComponents/HeaderTwoLineTitle';
import { selectMaintenanceAttachmentsState } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/domain/selectors';
import { createMaintenanceAttachmentDataFromResponse } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/factories/maintenanceAttachmentDataFactory';
import { MaintenanceAttachmentData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceAttachmentsTile/maintenanceAttachmentData';
import { selectMaintenanceUpdateState } from '@hkm/components/shared/Templates/Maintenance/shared/domain/selectors';
import APP_ROUTES from '@hkm/constants/routing.constants';
import { useFetchAssignedSheetOnNotification } from '@hkm/shared/hooks/useFetchAssignedSheetOnNotification';
import { Permission } from '@hkm/shared/permissions/enum/Permission';
import { usePermission } from '@hkm/shared/permissions/hooks/usePermission';
import { doesRoomHaveDoNotMoveFlag } from '@hkm/shared/reservations/hasDoNotMoveFlag';
import { ReservedKind } from '@hkm/shared/reservedKind/reservedKind';
import { AttendantRoom } from '@hkm/types/attendant/models/AttendantRoom';

import { AttendantTaskProgressType } from '@ac/library-api';
import { AcBody } from '@ac/mobile-components/dist/components/layout';
import { AlignItems, MobileColor } from '@ac/mobile-components/dist/enums';
import { usePrevious } from '@ac/mobile-components/dist/hooks';
import { Color, IconName, Size } from '@ac/web-components';

import './AttendantRoomDetails.css';

const AttendantRoomDetails: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const params = useParams();

  const hasReservationPermission = usePermission(
    Permission.HousekeepingReservationsViews
  );

  const notes = useSelector(selectNotes);
  const effectiveValues = useSelector(selectEffectiveValues);
  const assignedSheet = useSelector(selectAssignedSheetState);
  const isLoadingOrEditing = useSelector(selectIsLoadingOrEditingSheets);
  const maintenanceUpdateState = useSelector(selectMaintenanceUpdateState);
  const maintenanceAttachmentsState = useSelector(
    selectMaintenanceAttachmentsState
  );
  const attendantAssignmentDataState = useSelector(
    selectAttendantAssignmentDataState
  );
  const pendingRoomQueue = useSelector(selectPendingRoomQueue);
  const serviceRequested = useSelector(selectServiceRequested);

  const roomId = params.roomId ?? '';

  const nextRoomId = getNextRoomId();
  const room: AttendantRoom | undefined = useMemo(
    () => findAttendantRoom(roomId, assignedSheet.data),
    [assignedSheet.data, roomId]
  );
  const previousRoomState: AttendantRoom | undefined = usePrevious(room);

  const headerTwoLineTitleUpperText = useMemo(() => {
    if (room?.isInPendingRoomQueue && room.serviceRequested) {
      return `${t('GLOBAL.QUEUE_ROOM')}, ${t(
        'GLOBAL.GUEST_SERVICE_STATUS.VALUES.serviceRequested'
      )}`;
    }

    if (!room?.isInPendingRoomQueue && room?.serviceRequested) {
      return t('GLOBAL.GUEST_SERVICE_STATUS.VALUES.serviceRequested');
    }

    if (room?.isInPendingRoomQueue && !room.serviceRequested) {
      return t('GLOBAL.QUEUE_ROOM');
    }

    return t('ATTENDANT_ASSIGNMENTS.ROOM_DETAILS.TITLE');
  }, [t, room]);

  const attachments: MaintenanceAttachmentData[] = (
    attendantAssignmentDataState.attachments || []
  ).map((file) => createMaintenanceAttachmentDataFromResponse(file));

  useFetchAssignedSheetOnNotification();

  useEffect(() => {
    if (room) {
      dispatch(actions.setViewingAttendantRoom(room));
    }

    dispatch(actions.maintenanceAttachment.fetchAttachments.trigger());

    return () => {
      dispatch(actions.setViewingAttendantRoom(null));
    };
  }, [dispatch, room]);

  // fetch needed notes for reservations and store them
  useEffect(() => {
    if (
      effectiveValues?.displayNotesInMobileAttendant &&
      hasReservationPermission &&
      room
    ) {
      if (room?.currentReservedKind === ReservedKind.Reservation) {
        dispatch(
          appActions.fetchReservationNotes.trigger(
            room.currentReservationsIds || []
          )
        );
      } else {
        dispatch(
          appActions.fetchReservationNotes.trigger(
            room?.futureActiveReservations?.map(
              (reservation) => reservation.id
            ) || []
          )
        );
      }
    }
  }, [
    dispatch,
    room,
    effectiveValues?.displayNotesInMobileAttendant,
    hasReservationPermission,
  ]);

  const fabs = useMemo(
    () =>
      room &&
      room.activeTask && (
        <AttendantFabContainer
          room={room}
          sheetId={assignedSheet.data?.id ?? ''}
          activeTask={room.activeTask}
        />
      ),
    [room, assignedSheet.data]
  );

  const roomListRedirect = useRedirectToAssignment(roomId);

  const hasDoNotMoveFlag = doesRoomHaveDoNotMoveFlag(room);
  const doNotMoveIcon = useMemo(() => <DoNotMoveHeaderIcon />, []);

  if (
    !room?.isInPendingRoomQueue &&
    nextRoomId &&
    (!room || nextRoomId !== room.id)
  ) {
    //  room exist but it's different than calculated one (nextRoomId is calculated only if bypass option is turn on)
    return (
      <Navigate
        to={generatePath(APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_DETAILS, {
          roomId: nextRoomId,
        })}
      />
    );
  }

  if (
    // Redirect when room not exists in sheet
    !nextRoomId &&
    (!room ||
      (previousRoomState &&
        // Redirect if attendant has just completed task
        room.activeTask?.progress?.code !==
          (previousRoomState as AttendantRoom)?.activeTask?.progress?.code &&
        isTaskProgress(
          [
            AttendantTaskProgressType.Completed,
            AttendantTaskProgressType.Skipped,
          ],
          room?.activeTask
        )))
  ) {
    return <Navigate to={APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_LIST} />;
  }

  const showSpinner =
    isLoadingOrEditing ||
    !room ||
    notes.isFetching ||
    maintenanceUpdateState.isPending ||
    maintenanceAttachmentsState.isPending;
  const backwardPath = !nextRoomId
    ? APP_ROUTES.ATTENDANT_ASSIGNMENTS.ROOM_LIST
    : APP_ROUTES.ATTENDANT_ASSIGNMENTS.SHEET_SUMMARY;

  return (
    <>
      <ConnectedHeader
        color={
          room?.isInPendingRoomQueue || room?.serviceRequested
            ? MobileColor.Warning
            : undefined
        }
        close={backwardPath}
        showHelpButton={true}
        left={hasDoNotMoveFlag ? doNotMoveIcon : undefined}
      >
        <>
          {room && (
            <ac-flex alignItems={AlignItems.center}>
              {(room.isInPendingRoomQueue || room.serviceRequested) && (
                <ac-icon
                  class="ac-spacing-right-md"
                  icon={room.serviceRequested ? IconName.todo : IconName.queue}
                  color={Color.white}
                  size={Size.lg}
                />
              )}
              <HeaderTwoLineTitle
                upperText={headerTwoLineTitleUpperText}
                lowerText={room.roomNumber || '\u00A0'}
              />
            </ac-flex>
          )}
          {!room && t('ROOM_DETAILS.TITLE')}
        </>
      </ConnectedHeader>
      <AlertBar
        queueRoomsCount={pendingRoomQueue.length}
        serviceRequestedRoomsCount={serviceRequested.length}
        showLink={true}
        onShow={roomListRedirect}
      />
      <AcBody
        className="attendant-room-details height-percentage-100"
        showSpinner={showSpinner}
        fabs={fabs}
      >
        {room && assignedSheet.data && (
          <AttendantRoomDetailsBody
            room={room}
            selectedSheet={assignedSheet.data}
            maintenanceAttachments={attachments}
          />
        )}
      </AcBody>
    </>
  );
};

export default memo(AttendantRoomDetails);
