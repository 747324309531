import { useTranslation } from 'react-i18next';

import { AcSelectTranslation } from '@ac/mobile-components/dist/components/select/interfaces/AcSelectTranslation';

interface Output {
  translations: AcSelectTranslation;
}

export const useAcSelectTranslations = (): Output => {
  const { t } = useTranslation();

  return {
    translations: {
      searchPlaceholderTranslation: t('GLOBAL.FILL'),
      searchLabelTranslation: t('GLOBAL.SEARCH_LABEL'),
      itemNotFoundTranslation: t('GLOBAL.NO_CONTENT.TITLE'),
      confirmButtonTranslation: t('GLOBAL.CONFIRM'),
      checkAllButtonTranslation: t('GLOBAL.CHECK_ALL'),
      clearAllButtonTranslation: t('GLOBAL.CLEAR_ALL'),
      clearButtonTranslation: t('GLOBAL.CLEAR'),
    },
  };
};
